import React, { useEffect, useRef, useState } from "react"
import PageRoot from "../../../components/PageRoot"
import { Button, Col, Row, Space, Spin, Steps } from "antd"
import { useTranslation } from "react-i18next"
import SignUpStepCompileUserProfileForm from "../../../components/signUp/SignUpStepCompileUserProfileForm"
import SignUpStepCompleteForm from "../../../components/signUp/SignUpStepCompleteForm"
import { devLog, isSubscriptionValid, uiHandleSuccess } from "../../../utils"
import { gql, useMutation, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { SIGN_UP_DATA } from "../../../enums/UserPreferenceKeys"
import useCommonQueries from "../../../hooks/useCommonQueries"
import { navigate } from "gatsby"
import { SUBSCRIPTION } from "../../../enums/Plans"
import "../../../components/signUp/SingUpStep.less"
import useBraintree from "../../../hooks/useBraintree"
import QueryString from "query-string"

const { Step } = Steps

const GET_USER_SIGNUP_DATA = gql`
  query userSignUpData($couponCode: String, $now: timestamptz) {
    user_settings(where: { key: { _eq: "SIGN_UP_DATA" } }) {
      key
      value
    }
    user_subscriptions(order_by: { start_at: desc }, limit: 1) {
      state
      created_at
      end_at
      id
      start_at
      user_id
      braintree_subscription_id
    }
    braintree_customers {
      customer_id
    }
    coupon_codes(
      where: {
        code: { _eq: $couponCode }
        criterion: {
          _and: [
            {
              _or: [
                { exclusive_for_content_type: { _eq: SUBSCRIPTION } }
                { exclusive_for_content_type: { _is_null: true } }
              ]
            }
            {
              _or: [
                { start_at: { _lte: $now } }
                { start_at: { _is_null: true } }
              ]
            }
            { _or: [{ end_at: { _gt: $now } }, { end_at: { _is_null: true } }] }
          ]
        }
        consumed_at: { _is_null: true }
      }
    ) {
      code
      criterion {
        description
        discount_type
        end_at
        exclusive_for_content_id
        exclusive_for_content_type
        id
        percentage
        picture {
          s3_key
        }
        short_description
        start_at
        subtitle
        title
        value
      }
    }
  }
`

const UPSERT_USER_BASIC_PROFILE_MUTATION = gql`
  mutation insertUserProfile($data: user_profiles_insert_input!) {
    insert_user_profiles_one(
      object: $data
      on_conflict: {
        constraint: user_profiles_user_id_key
        update_columns: [
          address
          firstname
          lastname
          phone_number
          email
          newsletter
        ]
      }
    ) {
      id
    }
  }
`

const SignUpPage = ({ location }) => {
  const params = QueryString.parse(location.search)
  const [now, setNow] = useState(new Date())

  const { t } = useTranslation()
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [customerId, setCustomerId] = useState(null)
  const [signupData, setSignupData] = useState({
    plan: SUBSCRIPTION,
  })
  const [refreshing, setRefreshing] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const formRef = useRef()

  const { loading, error, data, refetch } = useQuery(GET_USER_SIGNUP_DATA, {
    fetchPolicy: "network-only",
    variables: {
      couponCode: params.coupon || "",
      now,
    },
  })
  const [upsertUserBasicProfile] = useMutation(
    UPSERT_USER_BASIC_PROFILE_MUTATION
  )
  const { createCustomer, subscribe } = useBraintree()
  const { upsertUserSetting } = useCommonQueries()

  useEffect(() => {
    if (refreshing) {
      setRefreshing(false)
    }
  }, [refreshing])

  useEffect(() => {
    if (data) {
      if (data.user_subscriptions[0]) {
        if (isSubscriptionValid(data.user_subscriptions[0])) {
          uiHandleSuccess({
            message: t("message:subscriptionAlreadyActive"),
            action: () => {
              navigate("/")
            },
          })
          return
        }
      }

      if (data.braintree_customers[0]) {
        setCustomerId(data.braintree_customers[0].customer_id)
      }

      const _sigUpData = data.user_settings.find(
        setting => setting.key === SIGN_UP_DATA
      )

      if (_sigUpData) {
        try {
          let remoteSignupData = JSON.parse(_sigUpData.value)
          delete remoteSignupData.error
          setSignupData(remoteSignupData)
          setRefreshing(true)
        } catch (error) {
          devLog({ parseRemoteSettingDataError: error })
        }
      }
    }
  }, [data])

  if (loading || refreshing) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const steps = [
    {
      title: t("signUp:step.profile"),
      ContentComponent: SignUpStepCompileUserProfileForm,
    },
    {
      title: t("signUp:step.complete"),
      ContentComponent: SignUpStepCompleteForm,
    },
  ]

  const next = () => {
    formRef.current?.submit()
  }

  const prev = () => {
    setCurrentStepIndex(currentStepIndex - 1)
  }

  const handleFormSubmit = async values => {
    setSubmitting(true)
    const newSignUpData = { ...signupData, ...values }
    devLog(newSignUpData)
    setSignupData(newSignUpData)

    const upsertData = {
      key: SIGN_UP_DATA,
      value: JSON.stringify(newSignUpData),
    }

    try {
      await upsertUserSetting(upsertData)
    } catch (error) {
      devLog({ upsertUserSettingError: error })
    }

    switch (currentStepIndex) {
      case 0:
        try {
          await upsertUserBasicProfile({
            variables: {
              data: {
                firstname: newSignUpData.firstname,
                lastname: newSignUpData.lastname,
                email: newSignUpData.email,
                phone_number: newSignUpData.phone_number,
              },
            },
          })

          if (!data.braintree_customers.length) {
            const customer = await createCustomer({
              firstname: newSignUpData.firstname,
              lastname: newSignUpData.lastname,
              email: newSignUpData.email,
              phone_number: newSignUpData.phone_number,
            })

            setCustomerId(customer.id)
          }
        } catch (error) {
          devLog({ upsertUserProfileError: error, newSignUpData })
        }
        break
      default:
        break
    }

    setCurrentStepIndex(currentStepIndex + 1)
    setSubmitting(false)
  }

  const ContentComponent = steps[currentStepIndex].ContentComponent

  return (
    <PageRoot title={t("label:subscription")} noLayout={true} isDark={false}>
      <Row justify="space-around">
        <Col xs={22} sm={22} md={20} lg={16} xl={14} xxl={14}>
          <div className="sing-up-page-content">
            <Space direction="vertical" size={50} style={{ width: "100%" }}>
              <div className="steps-content">
                <Spin spinning={submitting}>
                  <ContentComponent
                    initialValues={{
                      ...signupData,
                      coupon_code: data.coupon_codes[0]?.code ?? "",
                    }}
                    coupon={data.coupon_codes[0]}
                    formRef={formRef}
                    onFinish={handleFormSubmit}
                  />
                </Spin>
              </div>
              <div className="steps-indicator">
                <Row justify="center">
                  <Col xs={20} sm={20} md={12} lg={12} xl={10} xxl={6}>
                    <Steps
                      progressDot={false}
                      size="small"
                      current={currentStepIndex}
                    >
                      {steps.map(item => (
                        <Step key={item.title} />
                      ))}
                    </Steps>
                  </Col>
                </Row>
              </div>

              <Spin spinning={submitting}>
                <div className="steps-actions">
                  {currentStepIndex > 0 && currentStepIndex < steps.length - 1 && (
                    <Button
                      type="dashed"
                      style={{ margin: "0 8px" }}
                      disabled={submitting}
                      onClick={() => prev()}
                    >
                      {t("button:previous")}
                    </Button>
                  )}
                  {currentStepIndex < steps.length - 1 &&
                    currentStepIndex !== 0 && (
                      <Button
                        className="yellowsp"
                        disabled={submitting}
                        type="primary"
                        onClick={() => next()}
                      >
                        {t("button:next")}
                      </Button>
                    )}
                  {currentStepIndex === 0 && (
                    <Button
                      className="yellowsp"
                      disabled={submitting}
                      type="primary"
                      onClick={() => next()}
                    >
                      {t("button:next")}
                    </Button>
                  )}
                  {currentStepIndex === steps.length - 1 && (
                    <>
                      {signupData.error ? (
                        <Button
                          type="danger"
                          disabled={submitting}
                          onClick={() => {
                            const { error, ...otherProps } = signupData
                            setSignupData({ ...otherProps })
                            setCurrentStepIndex(3)
                          }}
                          style={{
                            color: "white",
                          }}
                        >
                          {t("button:retry")}
                        </Button>
                      ) : (
                        <Button
                          className="yellowsp"
                          disabled={submitting}
                          type="primary"
                          onClick={() => {
                            window.location.replace("/")
                          }}
                        >
                          {t("button:startNowOnSportScience")}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </Spin>
            </Space>
          </div>
        </Col>
      </Row>
    </PageRoot>
  )
}

export default SignUpPage
